<template>
  <v-main>

    <v-container
        id="update-account-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-edit-outline"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text>

          <div data-cy="error-message">

            <v-alert color="error"
                     dark
                     outlined
                     :key="error.id"
                     show variant="danger"
                     v-for="error in errors">
              {{ error }}
            </v-alert>

            <v-alert
                v-if="registerSuccess"
                type="success"
                outlined
                id="enrollSuccessMessage"
            >
              <p>Success. Your account has been created. Please click the login link to access it.</p>

              <p>For payment information, please log in to your account and click on the <span class="font-weight-bold">"Payment Information"</span> link under the <span class="font-weight-bold">"Billing"</span> menu item.</p>
            </v-alert>

          </div>

          <v-form ref="updateAccountForm"
                  @submit.prevent="validate"
                  v-model="valid"
                  v-if="!registerSuccess"
                  lazy-validation
          >

            <v-row v-if="user.accountNumber">
              <v-col
                  cols="12"
                  sm="4"
                  class="font-weight-medium"
              >
                Account Number:
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                {{ user.accountNumber }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.firstName"
                    label="First Name"
                    id="firstName"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.middleName"
                    label="Middle Name/Initial"
                    id="middleName"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.lastName"
                    label="Last Name"
                    id="lastName"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.address"
                    label="Address"
                    id="address"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.addressLine2"
                    label="Address line 2 (optional)"
                    id="addressLine2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.city"
                    label="City"
                    id="city"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    label="State"
                    v-model="computedState"
                    :items="statesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"
                    id="state"

                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.postalCode"
                    label="Zip/Postal Code"
                    id="postalCode"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select

                    label="Country"
                    v-model="computedCountry"
                    :items="countriesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    id="country"
                    :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
                  v-if="!userId"
              >
                <v-text-field
                    v-model="user.email"
                    label="Email Address"
                    id="emailAddress"
                    :rules="rules.emailAddress"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.phoneNumber"
                    label="Phone Number"
                    id="phoneNumber"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idNumber"
                    label="ID Number"
                    id="idNumber"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    v-model="user.idType"
                    label="ID Type"
                    :items="idTypes"
                    item-text="name"
                    item-value="id"
                    return-object
                    id="idType"
                    :rules="rules.required"
                ></v-select>
              </v-col>

            </v-row>

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idIssuedBy"
                    label="ID Issuing Country/State"
                    id="idState"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idExpires"
                    label="ID Expiration Year"
                    id="idExpires"
                    :rules="rules.idExpiration"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.birthYear"
                    label="Year of Birth"
                    id=birthYear
                    :rules="rules.birthYear"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-radio-group
                    v-model="user.gender"
                    row
                    id="genderRadioGroup"
                    :rules="rules.required"

                >
                  <template v-slot:label>
                    <div>Gender:</div>
                  </template>
                  <v-radio
                      v-for="(item, i) in genderOptions"
                      :key="i"
                      :label="item"
                      :value="item"
                  >
                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-radio-group
                    v-model="user.isMarried"
                    id="isMarriedRadioGroup"
                    row
                >
                  <template v-slot:label>
                    <div>Marital Status:</div>
                  </template>
                  <v-radio
                      label="Married"
                      :value="true">
                  </v-radio>
                  <v-radio
                      label="Not Married"
                      :value="false">
                  </v-radio>

                </v-radio-group>
              </v-col>

            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >

                <v-select
                    v-model="user.qualification"
                    label="Membership Qualification"
                    :items="qualifications"
                    item-text="description"
                    item-value="id"
                    id="qualification"
                    return-object
                    :rules="rules.required"
                />

              </v-col>
            </v-row>

            <v-row v-if="computedShowRecommenders">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[0].name"
                    v-bind:label="'Recommender 1 Name'"
                    id="recommender1Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[0].phoneNumber"
                    label="Recommender 1 Phone Number"
                    id="recommender1Phone"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[1].name"
                    label="Recommender 2 Name"
                    id="recommender2Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[1].phoneNumber"
                    label="Recommender 2 Phone Number"
                    id="recommender2Phone"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[2].name"
                    label="Recommender 3 Name"
                    id="recommender3Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[2].phoneNumber"
                    v-bind:label="'Recommender 3 Phone Number'"
                    id="recommender3Phone"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row v-if="!userId">

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="password"
                    label="Password"
                    type="password"
                    validate-on-blur
                    id="password"
                    :rules="rules.password"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="confirmPassword"
                    label="Re-Enter Password"
                    type="password"
                    id="confirmPassword"
                    :rules="rules.confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row v-if="userId">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-textarea
                    v-model="updateReason"
                    label="Reason for Change"
                    id="updateReason"
                    auto-grow
                    rows="1">
                </v-textarea>
              </v-col>
            </v-row>

            <v-row v-if="!userId">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-checkbox
                    v-model="user.agreeToTerms"
                    label="By submitting this form, I acknowledge that I have read and agree to the Tilyet Benevolence Fund bylaws."
                    id="agreeToTerms"
                    :rules="rules.required"
                ></v-checkbox>

                <p>
                  Click <a :href="`${baseUrl}docs/tilyet_bylaws_2024.pdf`" target="_blank">here</a> to read the Tilyet Benevolence Fund bylaws.
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      type="submit"
                      data-cy="submit-enroll-button"
                      id="submitEnroll"
                      dark
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </v-card-text>

      </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import validation from "@/utils/validation";
import {mapGetters} from "vuex";
import constants from "@/utils/constants";

export default {
  name: 'CoreAccountView',

  created() {
    if (this.isLoggedIn) {
      this.userId = this.getUserId
      this.fetchUser()
    }

    this.statesList = geoUtils.fetchUsStates()
    this.countriesList = geoUtils.fetchCountries()
    this.fetchIdTypes()
    this.fetchQualifications()
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn',
      getUserId: 'getUserId'
    }),
    computedState: {
      get() {
        return geoUtils.findUsStateByCode(this.user.state)
      },
      set(value) {
        this.user.state = value.code
      }
    },
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value.code
      }
    },
    computedShowRecommenders: function () {
       return (this.user.qualification !== null) && (this.user.qualification.id === 3)
    }
  },

  watch: {
    registerSuccess: function () {
      scroll(0, 0)
    },

    errors: function () {
      if (this.errors.length > 0) {
        scroll(0, 0)
      }
    }
  },

  methods: {
    validate () {
      this.errors = []
      if(this.$refs.updateAccountForm.validate()) {
        if (this.userId == null) {
          this.createUser()
        } else {
          this.updateUser()
        }
      }
    },
    fetchIdTypes() {
      api
          .fetchIdTypes()
          .then(response => {
            this.idTypes = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchUser() {
      api
          .fetchUser(this.userId)
          .then(response => {
            this.user = response.data
            this.fetchRecommenders()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    buildPayload() {
      let payload = {
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        address: this.user.address,
        addressLine2: this.user.addressLine2,
        city: this.user.city,
        state: this.user.state,
        postalCode: this.user.postalCode,
        country: this.user.country,
        phoneNumber: this.user.phoneNumber,
        birthYear: this.user.birthYear,
        gender: this.user.gender,
        idNumber: this.user.idNumber,
        idTypeId: this.user.idType.id,
        idExpires: this.user.idExpires,
        idIssuedBy: this.user.idIssuedBy,
        isMarried: this.user.isMarried,
        qualificationId: this.user.qualification.id,
        comment: this.updateReason
      }

      if (this.userId == null) {
        payload.email = this.user.email
        payload.password = this.password
        payload.agreeToTerms = this.user.agreeToTerms
      }

      return payload
    },
    createUser() {
      this.$gtag.event('enroll')
      let payload = this.buildPayload()
      api
          .createUser(payload)
          .then(response => {
            if (response.status === 201) {
              this.saveRecommenders(response.data.id)
              if (this.errors.length === 0) {
                this.registerSuccess = true
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    updateUser() {
      this.$gtag.event('update_account')
      let payload = this.buildPayload()
      api
          .updateUser(this.userId, payload)
          .then(response => {
            if (response.status === 200) {
              this.saveRecommenders(this.userId)
              if (this.errors.length === 0) {
                this.$router.push('/account/view')
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    saveRecommenders(uid) {
      for (const rec of this.recommenders) {
        if (Object.keys(rec).length === 0) {
          continue
        }
        const payload = {
          name: rec.name,
          phoneNumber: rec.phoneNumber
        }
        if (rec.id === undefined) {
          this.createRecommender(uid, payload)
        } else {
          this.updateRecommender(uid, rec.id, payload)
        }
      }
    },
    createRecommender(uid, payload) {
      api
          .createRecommender(uid, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    updateRecommender(uid, recId, payload) {
      api
          .updateRecommender(uid, recId, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchQualifications() {
      api
          .fetchQualifications()
          .then(response => {
            this.qualifications = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchRecommenders() {
      api
          .fetchRecommenders(this.user.id)
          .then(response => {
            if (response.data.length) {
              this.recommenders = response.data
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: false,
      errors: [],
      userId: null,
      registerSuccess: false,
      user: {
        accountNumber: null,
        firstName: null,
        middleName: null,
        lastName: null,
        address: null,
        addressLine2: null,
        city: null,
        state: null,
        postalCode: null,
        country: 'US',
        phoneNumber: null,
        birthYear: null,
        gender: null,
        idNumber: null,
        idType: null,
        idExpires: null,
        idIssuedBy: null,
        status: null,
        isMarried: null,
        qualification: null,
        recommenders: null,
      },
      password: null,
      confirmPassword: null,
      genderOptions: ['Male', 'Female', 'Other'],
      statesList: [],
      countriesList: [],
      idTypes: [],
      qualifications: [],
      updateReason: null,
      recommenders: [{}, {}, {}],
      baseUrl: process.env.BASE_URL,
      rules: {
        emailAddress: [
          v => validation.isEmailAddress(v) || 'must be a valid email address'
        ],
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        numeric: [
          v => validation.isInteger(v) || 'must be a number'
        ],
        birthYear: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isMoreThan(v, 1900) || 'must be after 1900',
          v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
        ],
        idExpiration: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isAfterOrEqualToCurrentYear(v) || 'must be after or equal to current year'
        ],
        password: [
          v => (v && v.length >= constants.PASSWORD_MIN_LENGTH) || `must have a minimum length of ${constants.PASSWORD_MIN_LENGTH}`
        ],
        confirmPassword: [
          v => (this.password === this.confirmPassword) || 'password confirmation must match password'
        ],
        recommender: [
          v => validation.isRequired(v) || 'required',
          v => (v && validation.isUnique(v, this.recommenders.map(el => el.name))) || 'must be unique'
        ]
      }
    }
  }
}
</script>
